::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

/* Handle */
::-webkit-scrollbar-thumb, ::-moz-range-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover, ::-moz-range-thumb:hover {
  background: #555; 
}
